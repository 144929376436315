<template>
  <div>
        <validation-observer ref="personalInfoForm">
        <div v-if='getDataLoader==false'>
        <b-card v-if="JSON.parse($route.query.isCourseEdit) == false">
            <b-form>
                <b-spinner variant="primary" v-if="editSpiner" large class="pageLoadSpinners"/>
                <b-row class="personal_information_wrapper">
                        <!-- Application Number -->
                        <b-col md="3">
                            <b-form-group label="Application Number">
                                {{applicationNumber}}
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Student Name">
                                <validation-provider
                                    #default="{ errors }"
                                    name="student name"
                                    rules="required"
                                >
                                    <b-form-select
                                        name="student name"
                                        disabled
                                        v-model="studentName"
                                        :options="studentOptions"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="Select"
                                    />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Intake">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Intake field"
                                    rules="required"
                                >
                                    <b-form-checkbox-group
                                        id="checkbox-group-1"
                                        v-model="intake"
                                        :options="intakeOptions"
                                        name="flavour-1"
                                        class="demo-inline-spacing"
                                    />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                </b-row>
            </b-form>
        </b-card>

        <!-- Colleges & Filters -->
        <b-card v-if="JSON.parse($route.query.isCourseEdit) == true">
            <!-- Filters -->
            <h4 class="card-header mb-1" style="padding:0px;">Filters</h4>
            <b-row>
                <b-col md="3">
                    <b-form-group label="College">
                        <!-- <b-form-select
                            v-model="collegeFilter"
                            :options="collegeFilterOptions"
                            placeholder="Select"
                        /> -->
                        <v-select
                            :value="collegeFilter"
                            v-model="collegeFilter"
                            :options="collegeFilterOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>

                </b-col>
                <b-col md="3">
                    <b-form-group label="University">
                        <!-- <b-form-select
                            v-model="universityFilter"
                            :options="universityFilterOptions"
                            placeholder="All"
                        /> -->
                        <v-select
                            :value="universityFilter"
                            v-model="universityFilter"
                            :options="universityFilterOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>

                </b-col>
                <b-col md="3">
                    <b-form-group label="Field">
                        <!-- <b-form-select
                           v-model="fieldFilter"
                           :options="fieldFilterOptions"
                           placeholder="All"
                        /> -->
                        <v-select
                            :value="fieldFilter"
                            v-model="fieldFilter"
                            :options="fieldFilterOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>

                </b-col>
                <b-col md="3">
                    <b-form-group label="Course">
                        <!-- <b-form-select
                           v-model="courseFilter"
                           :options="courseFilterOptions"
                           placeholder="All"
                        /> -->
                        <v-select
                            :value="courseFilter"
                            v-model="courseFilter"
                            :options="courseFilterOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Intake">
                        <!-- <b-form-select
                           v-model="intakeFilter"
                           :options="intakeFilterOptions"
                           placeholder="All"
                        /> -->
                        <v-select
                            :value="intakeFilter"
                            v-model="intakeFilter"
                            :options="intakeFilterOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>

                </b-col>
                <b-col md="3">
                    <b-form-group label="Exam">
                        <!-- <b-form-select
                           v-model="examFilter"
                           :options="examFilterOptions"
                           placeholder="All"
                        /> -->
                        <v-select
                            :value="examFilter"
                            v-model="examFilter"
                            :options="examFilterOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Score">
                        <!-- <b-form-select
                           v-model="examFilter"
                           :options="examFilterOptions"
                           placeholder="All"
                        /> -->
                        <v-select
                            :value="bandScore"
                            v-model="bandScore"
                            :options="bandScoreOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="3"
                    >
                        <b-form-group label="Mode Of Application">
                        <v-select
                            :value="modeOfApp"
                            v-model="modeOfApp"
                            :options="modeOfAppOptions"
                            class="w-100"
                            placeholder="All"
                        />
                        </b-form-group>
                    </b-col>
                <b-col md="3">
                    <b-form-group label="German Language">
                        <v-select
                            :value="germanLang"
                            v-model="germanLang"
                            :options="germanLangOptions"
                            class="w-100"
                            placeholder="All"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <hr>
            <!-- Colleges -->
            <b-row class="mb-1">
                <b-col cols="12" md="6"            
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <h4 class="table_title" style="padding:0px;">College</h4>
                </b-col>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                    <v-select
                        v-model="perPage"
                        style="width: 90px"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="pageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                    />
                </b-col>
            </b-row>
            
          
            <div class="mb-2">
                <b-row>             
                <!-- PAGINATION DROPDOWN -->
                <b-col md="12">  
                                 
                 <b-table
                    striped
                    hover
                    v-model="applicationTable"
                    responsive
                    show-empty
                    :busy="isFetching"

                    ref="appTable"

                    empty-text="No matching records found"
                    :per-page="perPage"
                    :current-page="1"
                    :items="filterList"
                    :fields="columns"        
                    no-local-sorting                  
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @sort-changed="handleSortChange"
                    class="position-relative p0_table create-application_wrapper"
                >
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner variant="primary" large/>
                    </div>
                </template>
                <template #head(allSelected)="">
                    <!-- <input type='checkbox' value='true' v-model="allToggleChecked" @change="checkedAll"/>                    -->
                    <label></label>
                </template>
                 <template #cell(allSelected)="data">
                    <input type='checkbox' value="true" v-model="data.item.allSelected" :disabled='data.item.selectedColl==true' @change="checkedEachAppl(data)"/>  
                </template>
                    <!-- COLUMN: Number -->
                    <template #cell(id)="data">                      
                        <!-- {{Number(data.index) + 1}} -->
                        {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                    </template>
                    <!-- COLUMN: INTAKE                        -->
                    <template #cell(dispIntake)="data">
                        <div
                        v-for="(dataIntake,Ind) in data.item.dispIntake"
                        :key="Ind"
                        >
                        {{dataIntake}}<br>
                        </div>
                    </template>
                    <!-- COLUMN: DEADLINE                        -->
                    <template #cell(dispIntakeDeadline)="data">
                        <div
                        v-for="(dataIntake,Ind) in data.item.dispIntakeDeadline"
                        :key="Ind"
                        >
                        {{dataIntake.DispDate ? dataIntake.DispDate : ''}}<br>
                        </div>
                    </template>
                    <!-- COLUMN: EXAMS AND BAND SCORE                        -->
                    <template #cell(examsScore)="data">
                        <div
                        v-for="(dataIntake,Ind) in data.item.examsScore"
                        :key="Ind"
                        >
                        {{dataIntake.title}}&nbsp;{{dataIntake.score}}<br>
                        </div>
                    </template> 
                    <template #cell(remark)="data">
                        <span v-if="data.item.remark" :title="data.item.remark" class="remarktext__elisis">{{data.item.remark}}</span>
                        <span v-else>--</span>
                    </template>
                    <template #cell(germanLanguage)="data">
                        <span v-if="data.item.germanLanguage" :title="data.item.germanLanguage" class="remarktext__elisis">{{data.item.germanLanguage}}</span>
                        <span v-else>--</span>
                    </template>                     
                </b-table>
                  <span>
                        <p class="text-danger" v-if='selectedCllgError!==""'>{{selectedCllgError}}</p>
                  </span> 
                </b-col>
                <!-- PAGINATION TEXT -->
                <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ totalData }} entries</span>
                </b-col>
                 <!-- PAGINATION FOOTER -->
                <b-col
                md="12"
                >                
                <b-pagination
                v-model="currentPage"                   
                :total-rows="totalData"
                :per-page="perPage"
                @change="handlePageChange"
                align="right"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                />
                </b-col>
                </b-row>
            </div>

        </b-card>
        </div>
        </validation-observer>
        <b-spinner variant="primary" v-if="getDataLoader" large class="pageLoadSpinners"/>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormCheckbox,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,
  BForm,BFormTextarea,BFormSelect,BFormCheckboxGroup, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'

//vee-validator
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'
import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double } from 'vee-validate/dist/rules';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'
import firebaseTimeStemp from 'firebase'
import { ref, onUnmounted } from '@vue/composition-api'

const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

import importModel from '@/views/components/importModel.vue'
// import useUsersList from './useCollegeList'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import moment from 'moment'
import applicationListVue from './applicationList.vue'
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
import {getCollectionDataTypesense, typesenseFetchAll } from '@/utils/Typesense/queries'
export default {
    name:'create_new_application',
    directives: {
        Ripple,
    },
    components: {
        BSpinner,
        ValidationProvider, 
        ValidationObserver,
        BCard,
        BRow,
        BCol,
        BForm,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BModal,
        BFormGroup,
        BFormRadio,
        BFormDatepicker,
        BFormTextarea,
        BFormSelect,
        BFormCheckbox,
        BFormCheckboxGroup,
        vSelect,
        VueGoodTable,
        importModel
    },
    data(){
        return{
            isLoad:false,
            allColleges:[],
            prevSelected: [],
            previousData: [],
            applicationNumber:'',
            studentName:null,
            intake:[],
            studentOptions:[{value:null,text:'Select'}],
            intakeOptions:[
                {value:'WS',text:'WS'},
                {value:'SS',text:'SS'}
            ],
            selected:[],
            allCollegeSelected:[],
            applicationList : [],
            applicationTable:[],
            columns:[             
                { key: 'allSelected', label:'allSelected', sortable: false },
                // { key: 'id', label:'No', sortable: false },
                { key: 'college_name', label:'College Name', sortable: true },
                { key: 'university_name',label:'University Name', sortable: true },
                { key: 'dispFieldName',label:"Field Name", sortable: true },
                { key: 'dispCourseName',label:"Course Name", sortable: true},
                { key: 'dispmodeOfApplication',label:"Mode of Application", sortable: true  },
                { key: 'dispIntake',label:"Intake" },
                { key: 'applicationFee',label:"application Fee", sortable: true },
                { key: 'tutionFee',label:"Tution Fee", sortable: true },
                { key: 'dispIntakeDeadline',label:"Application DeadLine"},
                { key: 'germanLanguage',label:"German Language", sortable: true},
                { key: 'examsScore',label:"English Scores"},  
                { key: 'remark',label:"Remark", sortable: true},              
            ],
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            isSpiner:false,
            allToggleChecked:false,
            toggleChecked:[],
            allStuidents:[],
            selectedCllgError:"",
            collegeFilter:null,
            collegeFilterOptions:[],
            universityFilter:null,
            universityFilterOptions:[],
            fieldFilter:null,
            fieldFilterOptions:[],
            courseFilter:null,
            courseFilterOptions:[],
            intakeFilter:null,
            intakeFilterOptions:[
                {label:'WS',value:"WS"},
                {label:"SS",value:"SS"},
            ], 
            examFilter:null,
            examFilterOptions:[
                {label:"IELTS",value:"IELTS"}
            ],
            getDataLoader:false,
            editSpiner:false,

            bandScore:null,
            bandScoreOptions:[
                {label:4,value:"4"},
                {label:4.5,value:"4.5"},
                {label:5,value:"5"},
                {label:5.5,value:"5.5"},
                {label:6,value:"6"},
                {label:6.5,value:"6.5"},
                {label:7,value:"7"},
                {label:7.5,value:"7.5"},
                {label:8,value:"8"},
                {label:8.5,value:"8.5"},
                {label:9,value:"9"}
            ],
            modeOfApp:null,
            modeOfAppOptions:[
                {label:'Direct',value:'direct'},
                {label:'Uniassist',value:'uniassist'}
            ],
            germanLang:null,
            germanLangOptions:[
                {label:'None',value:"None"},
                {label:'A1',value:"A1"},
                {label:'A2',value:"A2"},
                {label:'B1',value:"B1"},
                {label:'B2',value:"B2"},
            ],
            nextDisabled:false,
            previuosDisabled:false,
            fetchMoreDataStop: false,
            sortingVal: null,
            firstDoc: null,
            lastDoc: null,
            isFetching : false,
            totalData: 0,
            previousArray: [],
            sortContect: null,
        }
    },
    watch: {
    $route: function () {     
        // this.getData(); 
        // this.getDirectData()     
        getCollectionData(`${dbCollections.APPLICATIONS}/${this.$route.params.id}`).then((response)=>{
            if(response.exists) {
                response.data()?.selectedCollege?.forEach((selectedCourseObject)=>{
                    this.previousData.push(selectedCourseObject); 
                })
            }
            // this.fetchCourseDataFromCollegeData();
            this.fetchCourseDataFromCollegeDataFromTypesense();
        })
    }
   },
   watch: {
        germanLang: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        bandScore: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        examFilter: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        intakeFilter: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        modeOfApp : function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        collegeFilter: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        universityFilter: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        courseFilter: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        fieldFilter: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        perPage: function () {
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
    },
    // setup() {
    //     const USER_APP_STORE_MODULE_NAME = 'app-user'

    //     // Register module
    //     // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    //     // UnRegister on leave
    //     onUnmounted(() => {
    //         if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    //     })

    //     const isAddNewUserSidebarActive = ref(false)

    //     const roleOptions = [
    //     { label: 'Admin', value: 'admin' },
    //     { label: 'Author', value: 'author' },
    //     { label: 'Editor', value: 'editor' },
    //     { label: 'Maintainer', value: 'maintainer' },
    //     { label: 'Subscriber', value: 'subscriber' },
    //     ]

    //     const planOptions = [
    //     { label: 'Basic', value: 'basic' },
    //     { label: 'Company', value: 'company' },
    //     { label: 'Enterprise', value: 'enterprise' },
    //     { label: 'Team', value: 'team' },
    //     ]

    //     const statusOptions = [
    //     { label: 'Pending', value: 'pending' },
    //     { label: 'Active', value: 'active' },
    //     { label: 'Inactive', value: 'inactive' },
    //     ]

    //     const {
    //     fetchUsers,
    //     tableColumns,
    //     perPage,
    //     currentPage,
    //     totalUsers,
    //     dataMeta,
    //     perPageOptions,
    //     searchQuery,
    //     sortBy,
    //     isSortDirDesc,
    //     refUserListTable,
    //     refetchData,
    //     deleteField,

    //     createCollege,
    //     editCollege,

    //     // UI
    //     resolveUserRoleVariant,
    //     resolveUserRoleIcon,
    //     resolveUserStatusVariant,

    //     // Extra Filters
    //     filterCountry,
    //     filterState,
    //     filterCity
    //     } = useUsersList()

    //     return {

    //     // Sidebar
    //     isAddNewUserSidebarActive,

    //     fetchUsers,
    //     tableColumns,
    //     perPage,
    //     currentPage,
    //     totalUsers,
    //     dataMeta,
    //     perPageOptions,
    //     searchQuery,
    //     sortBy,
    //     isSortDirDesc,
    //     refUserListTable,
    //     refetchData,
    //     deleteField,
    //     createCollege,
    //     editCollege,

    //     // Filter

    //     // UI
    //     resolveUserRoleVariant,
    //     resolveUserRoleIcon,
    //     resolveUserStatusVariant,

    //     roleOptions,
    //     planOptions,
    //     statusOptions,

    //     // Extra Filters
    //     filterCountry,
    //     filterState,
    //     filterCity,      
    //     }
    // },
    created() {
        var self = this   
        // self.getData();    
        self.isLoad = true;
        // self.getDirectData();
        this.isFetching = true;
        getCollectionData(`${dbCollections.APPLICATIONS}/${this.$route.params.id}`).then((response)=>{
            if(response.exists) {
                response.data()?.selectedCollege?.forEach((selectedCourseObject)=>{
                    this.previousData.push(selectedCourseObject); 
                })
            }
            // this.fetchCourseDataFromCollegeData();
            this.fetchCourseDataFromCollegeDataFromTypesense()
        })
        let array = [
            {filterArray:'collegeFilterOptions',incfields:'collegeId',groupby:'collegeName'},
            {filterArray:'universityFilterOptions',incfields:'universityId',groupby:'universityName'},
            {filterArray:'fieldFilterOptions',incfields:'fieldName.name',groupby:'fieldName.name'},
            {filterArray:'courseFilterOptions',incfields:'courseName.title',groupby:'courseName.title'}
        ]
        array.forEach((e)=>{
            self.fecchGroupByData(e);
        })
        // self.getApplicationNo()
        self.$root.$on('createNewApplication',()=>{    
            if(self.$refs.personalInfoForm)
            {
                self.createNewApplicationFunction()
            }
        })

        self.$root.$on('cancelEditApplication',()=>{
            if(self.editSpiner == false){
                self.$router.push({name:'application-mgmt-Info',params:{id:self.$route.params.id,name:self.$route.params.name}})
            }
        })
        self.$root.$on('updateEditApplication',()=>{
            if(self.previousArray.length > 0 && !self.isLoad){
                if(self.editSpiner == false){
                self.editSpiner = true;    
                let suggestedArray = []
                self.previousData.forEach(vals=>{
                    if(vals.allSelected == true){
                        suggestedArray.push(vals)
                    }
                })
                self.previousArray.forEach(vals=>{
                    if(vals.allSelected == true){
                        let index = suggestedArray.findIndex((e)=>e.courseUniqueId == vals.courseUniqueId);
                        if(index == -1) {
                            suggestedArray.push(vals)
                        }
                    }
                })

                var selectedlist = [];
                if(suggestedArray && suggestedArray.length!=0)
                {
                    suggestedArray.forEach(data=>{
                        selectedlist.push({
                            allSelected: data.allSelected,
                            college_name: data.college_name,
                            courseCode: data.courseCode,
                            // courseIndex: data.courseIndex,
                            dispCourseName: data.dispCourseName,
                            dispFieldName: data.dispFieldName,
                            dispIntake: data.dispIntake,
                            dispIntakeDeadline: data.dispIntakeDeadline,
                            dispmodeOfApplication: data.dispmodeOfApplication,
                            examsScore: data.examsScore,
                            fieldId: data.fieldId,
                            id: data.id,
                            isDeleted: data.isDeleted || false,
                            status: data.status||'',
                            university_name: data.university_name,
                            tutionFee: data.tutionFee||'0',
                            applicationFee: data.applicationFee||'0',
                            universityId: data.universityId||'',
                            courseUniqueId: data.courseUniqueId || '',
                            germanLanguage: data.germanLanguage || '',
                            remark: data.remark || ''
                        })
                    })
                }

                let added = [];
                let removed = [];

                this.prevSelected.forEach((prevSel) => {
                    const index = selectedlist.findIndex((x) => x.courseUniqueId == prevSel.courseUniqueId)

                    if(index === -1) {
                        removed.push(prevSel)
                    }
                })

                selectedlist.forEach((prevSel) => {
                    const index = this.prevSelected.findIndex((x) => x.courseUniqueId == prevSel.courseUniqueId)
                    if(index === -1) {
                        added.push(prevSel)
                    }
                })

                added = added.map((x) => {
                    let obj = {};

                    obj.courseCode = x.courseCode;
                    obj.college_name = x.college_name;
                    obj.dispFieldName = x.dispFieldName;
                    obj.fieldId = x.fieldId;
                    obj.clgId = x.id;
                    obj.university_name = x.university_name;
                    return obj;
                })
                removed = removed.map((x) => {
                    let obj = {};

                    obj.courseCode = x.courseCode;
                    obj.college_name = x.college_name;
                    obj.dispFieldName = x.dispFieldName;
                    obj.fieldId = x.fieldId;
                    obj.clgId = x.id;
                    obj.university_name = x.university_name;
                    return obj;
                })

                if(removed.length || added.length) {
                    db.collection(dbCollections.APPLICATIONS).doc(this.$route.params.id).collection("application_history")
                    .add({
                        createdAt: firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                        updatedAt: firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                        added,
                        userId: JSON.parse(localStorage.getItem('userData'))?.id,
                        userName: JSON.parse(localStorage.getItem('userData'))?.fullName,
                        removed
                    })
                    .catch((error) => {
                        console.error("ERROR: ", error);
                    })
                }

                let updateObj = {
                    updatedAt : firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                    intake : self.intake,
                    selectedCollege : selectedlist
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,this.$route.params.id,updateObj,res=>{
                    self.$root.$emit('showToastMessage','Application Update Successfully','success')
                    self.editSpiner = false; 
                    self.$root.$emit('stopSpinner')
                    self.$router.push({name:'application-mgmt-Info',params:{id:self.$route.params.id,name:self.$route.params.name}})
                })
                }
            }else{   
                let updateObj = {
                    updatedAt : firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                    intake : self.intake,
                }
                updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,this.$route.params.id,updateObj,res=>{
                    self.$root.$emit('showToastMessage','Application Update Successfully','success')
                    self.$root.$emit('stopSpinner')   
                    self.$router.push({name:'application-mgmt-Info',params:{id:self.$route.params.id,name:self.$route.params.name}})
                })
            }
        })
        // db
        //     .collection(dbCollections.USERS)
        //     .get()
        //     .then((querySnapshot)=>{
        //         querySnapshot.forEach(val=>{
        //             if(!val.isDeleted && val.role=='student')
        //             {
                       
        //             }
        //         })
        //     })
    },
    methods: {
        fecchGroupByData(obj){
            try {
                const {filterArray,groupby,incfields} = obj
                let searchParameters = {
                    collectionName: `${dbCollections.COURSES}`,
                    search: {
                        'q'            : '*',
                        'filter_by' : 'isDeleted:!=true',
                        'group_by'     : groupby,
                        'group_limit'  : '1',
                        'include_fields': incfields,
                        'per_page': 5
                    }
                }

                getCollectionDataTypesense(searchParameters).then((result)=>{
                    typesenseFetchAll(dbCollections.COURSES,result.grouped_hits,result.found,searchParameters.search,true,(groupResult)=>{
                        let array = [];
                        if(groupResult.finalArray.length) {
                            groupResult.finalArray.forEach((ele)=>{
                                array = [...new Set(ele.group_key),...array]
                            })
                        }
                        array.forEach((x)=>{
                            this[filterArray].push({label: x, value: x});
                        })
                    })
                })
            } catch (error) {
                console.error(error)
            }
        },
        handleSortChange(context) {
            let sortFieldObject = {
                college_name : "collegeName",
                university_name : "universityName",
                dispFieldName : "fieldName.name",
                dispCourseName : "courseName.title",
                dispmodeOfApplication : "modeOfApplication", 
                dispIntake : "", 
                applicationFee : "applicationFees", 
                tutionFee : "tutionFees",
                dispIntakeDeadline : "", 
                germanLanguage : "germanLanguage", 
                examsScore : "", 
                remark : "remark"
            }
            const {sortBy,sortDesc} = context
            this.sortContect = {sortBy:sortFieldObject[sortBy],sortDesc};
            this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        async handlePageChange(newPage) {
            this.currentPage = newPage;
            this.applicationList.forEach(ele => {
                if (ele.allSelected == true) {
                    let findIndex = this.previousArray.findIndex((e) => e.courseUniqueId == ele.courseUniqueId)
                    if (findIndex == -1) {
                        this.previousArray.push(ele);
                    }
                } else {
                    let findIndex = this.previousArray.findIndex((e) => e.courseUniqueId == ele.courseUniqueId)
                    if (findIndex!= -1) {
                        this.previousArray.splice(findIndex, 1);
                    }
                }
            })
            await this.fetchCourseDataFromCollegeDataFromTypesense(false)
        },
        fetchCourseDataFromCollegeDataFromTypesense(resetResult = true){
            try {
                var self = this;
                if(resetResult) {
                    this.isFetching = true;
                }

                let searchParameters = {
                    collectionName: `${dbCollections.COURSES}`,
                    search: {
                        q:"*",
                        page: self.currentPage,
                        per_page: self.perPage,
                    }
                }

                let filter = 'isDeleted:!=true'
                if (this.germanLang !== null && this.germanLang !== '') {
                    if(filter !== '') {
                        filter += ` && `
                    }
                    filter += `germanLanguage:= ${this.germanLang.value}`
                }
                if (this.bandScore !== null && this.bandScore !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    // filter += `exams.score:= ${this.bandScore.value}`
                    filter += `exams.score:= [${this.bandScoreOptions.filter(e=>e.label <= this.bandScore.value).map(e=>e.value).join(',')}]`
                }
                if (this.examFilter !== null && this.examFilter !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    filter += `exams.title:= ${this.examFilter.value}`
                }
                if (this.intakeFilter !== null && this.intakeFilter !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    filter += `intake:[${this.intakeFilter.value}]`
                }
                if (this.modeOfApp !== null && this.modeOfApp !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    filter += `modeOfApplication:[${this.modeOfApp.value}]`
                }
                if (this.collegeFilter !== null && this.collegeFilter !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    filter += `collegeName:=${this.collegeFilter.value}`
                }
                if (this.universityFilter !== null && this.universityFilter !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    filter += `universityName:=${this.universityFilter.value}`
                }
                if (this.courseFilter !== null && this.courseFilter !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    filter += `courseName.title:=${this.courseFilter.value}`
                }
                if (this.fieldFilter !== null && this.fieldFilter !== '') {
                    if(filter !== '') {
                     
                        filter += ` && `
                    }
                    filter += `fieldName.name:=${this.fieldFilter.value}`
                }
                if (filter) {
                    searchParameters.search.filter_by = filter;
                }
                if (this.sortContect) {
                    searchParameters.search.sort_by = `${(this.sortContect.sortBy && this.sortContect.sortBy !== '') ? `${this.sortContect.sortBy}: ${this.sortContect.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
                }
                getCollectionDataTypesense(searchParameters).then((result)=>{
                    if(result.hits.length) {
                        self.totalData = result.found
                        const arrayData = result.hits.map(x => {
                            const course = x.document;
                            course.applicationDeadline.forEach(deadlineDate => {
                                deadlineDate.DispDate = moment(new Date(deadlineDate.date)).format('DD MMM');
                            });
                            if(this.previousData !== undefined && this.previousData.length > 0){
                                let searchIndex = this.previousData.findIndex(vals=>{
                                    return vals.courseUniqueId == course.courseUniqueId
                                }) 
                                if(searchIndex > -1){
                                    course.selectdCollge = true
                                }else{
                                    course.selectdCollge = false   
                                }
                            }

                            return {
                                id: course.id,
                                universityId: course.universityId,
                                courseUniqueId: course.courseUniqueId,
                                selectedColl: course.selectdCollge,
                                allSelected: course.selectdCollge === true ? true : false,
                                courseCode: course.courseName.id,
                                college_name: course.collegeName,
                                university_name: course.universityName,
                                dispFieldName: course.fieldName.name,
                                dispCourseName: course.courseName.title,
                                dispmodeOfApplication: course.modeOfApplication,
                                dispIntake: course.intake,
                                applicationFee: course.applicationFees,
                                tutionFee: course.tutionFees,
                                dispIntakeDeadline: course.applicationDeadline,
                                examsScore: course.exams,
                                isDeleted: course.isDeleted || false,
                                fieldId: course.fieldName.id,
                                course: course,
                                germanLanguage: course.germanLanguage,
                                remark: course.remark,
                            };
                        });

                        // const idSet = new Set(self.applicationList.map(course => course.id));
                        // self.applicationList = [...self.applicationList, ...arrayData.filter(course => !idSet.has(course.id))];
                        self.applicationList = arrayData;

                        this.bindEditData()
                        this.isFetching = false;
                        this.isLoad = false;
                    } else {
                        self.applicationList = []
                        self.totalData = 0;
                        this.isLoad = false;
                        this.isFetching = false;
                    }
                })
            } catch (error) {
                console.error(error)
            }
        },
        fetchCourseDataFromCollegeData(direction = '',refetchIng=false) {
            try {
                if(refetchIng == false) {
                    this.isFetching = true;
                    this.examFilterOptions=[];
                    this.intakeFilterOptions=[];
                    this.courseFilterOptions=[];
                    this.fieldFilterOptions=[];
                    this.universityFilterOptions=[];
                    this.collegeFilterOptions=[];
                }

                let limit = 30
                let orderByValue = {field: "createdAt",value: 1}
                let refDoc;
                let refDoc1;
                let limitToLast = 0;
                if(direction == 'next') {
                    orderByValue = {field: "createdAt",value: 1}
                    refDoc = {
                        doc: this.lastDoc
                    }
                } else if(direction == 'previous') {
                    orderByValue = {field: "createdAt",value: 1}
                    refDoc1 = {
                        doc: this.firstDoc
                    }
                    limitToLast = limit
                }
                if(this.sortingVal && this.sortingVal.key!=null) {
                    orderByValue = {field: this.sortingVal.key,value: this.sortingVal.value == true ? 1 : 0}
                }

                getCollectionData(`${dbCollections.COLLEGES}`,[{field: "isDeleted",operation: "==",value: false}], orderByValue,limit,'','',refDoc?.doc || '',refDoc1?.doc || '',limitToLast)
                    .then(async(querySnapshot) => {
                        if(querySnapshot.docs.length == 0) {
                            this.isFetching = false;
                            if(direction == 'next') {
                                this.nextDisabled = true;
                                this.lastDoc = '';
                            } else if(direction == 'previous') {
                                this.previuosDisabled = true;
                                this.firstDoc = '';
                            }
                            return;
                        }
                        const docs =  querySnapshot.docs?.map((x) => ({...x.data(), id: x.id}));
                        if(direction == 'next') {
                            this.lastDoc = querySnapshot.docs?.[querySnapshot.docs.length - 1];
                        } else if(direction == 'previous') {
                            this.firstDoc = querySnapshot.docs?.[0];
                        }
                        this.applicationList = docs.reduce((corseList, doc) => {
                            const displayList = doc.courses
                                .filter(course => course.isDeleted === undefined || !course.isDeleted)
                                .map(course => {
                                    if (course.applicationDeadline.length > 0) {
                                        course.applicationDeadline.forEach(deadlineDate => {
                                        deadlineDate.DispDate = moment(new Date(deadlineDate.date)).format('DD MMM');
                                        });
                                    }

                                    if(this.previousData !== undefined && this.previousData.length > 0){
                                        let searchIndex = this.previousData.findIndex(vals=>{
                                            return vals.courseUniqueId == course.courseUniqueId
                                        }) 
                                        if(searchIndex > -1){
                                            course.selectdCollge = true
                                        }else{
                                            course.selectdCollge = false   
                                        }
                                    }

                                    return {
                                        id:doc.id,
                                        allSelected :false,
                                        selectedCollege: course.selectdCollge,
                                        courseCode:course.courseName.id,
                                        college_name:doc.collegeName,
                                        university_name:doc.universityName,
                                        dispFieldName:course.fieldName.name,
                                        dispCourseName:course.courseName.title,
                                        dispmodeOfApplication:course.modeOfApplication,
                                        dispIntake:course.intake,
                                        applicationFee:course.applicationFees,
                                        tutionFee:course.tutionFees,
                                        dispIntakeDeadline:course.applicationDeadline,
                                        examsScore:course.exams,
                                        isDeleted:doc.isDeleted,
                                        fieldId:course.fieldName.id,
                                        address:doc.addressLine1 + " " + doc.addressLine2,
                                        course:course,
                                        city:doc.city,
                                        state:doc.state,
                                        country:doc.country,
                                        universityId: doc.universityId,
                                        courseUniqueId: course.courseUniqueId,
                                        germanLanguage:course.germanLanguage,
                                        remark:course.remark,
                                    };
                                }).filter((e)=>e.selectedCollege == false);

                            return corseList.concat(displayList);
                        }, this.applicationList);

                        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
                        this.firstDoc = querySnapshot.docs[0];
                        if (this.nextDisabled === false && this.fetchMoreDataStop == false) {
                            this.fetchCourseDataFromCollegeData('next',true);
                            if(this.applicationList.length > 0){
                                this.applicationList.forEach(appData=>{  
                                    if(appData.allSelected == undefined){
                                        appData.allSelected = false
                                    }                          
                                    if(appData.examsScore.length > 0){                               
                                        appData.examsScore.forEach(examData=>{
                                            if(this.examFilterOptions.length > 0){
                                                let examIndex = this.examFilterOptions.findIndex(findVal=>{
                                                    return findVal == examData.title
                                                })
                                                if(examIndex <= -1){
                                                    this.examFilterOptions.push(examData.title)
                                                }
                                            }else{
                                                this.examFilterOptions.push(examData.title)
                                            }
                                        })
                                    }
                                    if(appData.dispIntake.length > 0){
                                        appData.dispIntake.forEach(examData=>{
                                            if(this.intakeFilterOptions.length > 0){
                                                let examIndex = this.intakeFilterOptions.findIndex(findVal=>{
                                                    return findVal == examData
                                                })
                                                if(examIndex <= -1){
                                                    this.intakeFilterOptions.push(examData)
                                                }
                                            }else{
                                                this.intakeFilterOptions.push(examData)
                                            }
                                        })
                                    }
                                    if(this.courseFilterOptions.length > 0){
                                        let indexSearch = this.courseFilterOptions.findIndex(ele=>{
                                            return ele == appData.dispCourseName;
                                        })
                                        if(indexSearch <= -1){
                                            this.courseFilterOptions.push(appData.dispCourseName)
                                        }
                                    }else{
                                    this.courseFilterOptions.push(appData.dispCourseName)
                                    }
                                    if(this.fieldFilterOptions.length > 0){
                                        let indexSearch = this.fieldFilterOptions.findIndex(ele=>{
                                            return ele == appData.dispFieldName;
                                        })
                                        if(indexSearch <= -1){
                                            this.fieldFilterOptions.push(appData.dispFieldName)
                                        }
                                    }else{
                                    this.fieldFilterOptions.push(appData.dispFieldName)
                                    }
                                    if(this.universityFilterOptions.length > 0){
                                        let indexSearch = this.universityFilterOptions.findIndex(ele=>{
                                            return ele == appData.university_name;
                                        })
                                        if(indexSearch <= -1){
                                            this.universityFilterOptions.push(appData.university_name)
                                        }
                                    }else{
                                    this.universityFilterOptions.push(appData.university_name)
                                    }
                                    if(this.collegeFilterOptions.length > 0){
                                        let indexSearch = this.collegeFilterOptions.findIndex(ele=>{
                                            return ele == appData.college_name;
                                        })
                                        if(indexSearch <= -1){
                                            this.collegeFilterOptions.push(appData.college_name)
                                        }
                                    }else{
                                    this.collegeFilterOptions.push(appData.college_name)
                                    }
                                })
                                setTimeout(()=>{
                                    this.applicationList.sort((a,b)=>{
                                        return b.allSelected - a.allSelected
                                    });
                                    this.isLoad = false;
                                },1000)
                            }
                            this.bindEditData()
                        }
                        this.isFetching = false;
                    })
                    .catch((error) => {
                        this.isFetching = false;
                        console.error("Error in fetchCourseDataFromCollegeData", error);
                    });
            } catch (error) {
                this.isFetching = false;
                console.error("Error in fetchCourseDataFromCollegeData", error);
            }
        },
        getApplicationNo()
        {
            var self = this
            db
            .collection(dbCollections.APPLICATIONS)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                var newIntakeNo=0
                if(!querySnapshot.empty)
                {
                    querySnapshot.forEach(val=>{
                        if(!val.isDeleted && val.role=="student")
                        {
                            var applicationNumber = val.applicationNumber
                            if(applicationNumber>=newIntakeNo)
                            {
                                newIntakeNo = parseInt(applicationNumber)+1
                            }
                            var zeroes = 6-newIntakeNo.toString().length

                            for (let i = 0; i < zeroes; i++) {
                                newIntakeNo='0'+newIntakeNo
                            }
                        }
                    })
                    if(newIntakeNo==0)
                    {
                        newIntakeNo='000001'
                    }
                }
                else
                {
                    newIntakeNo='000001'
                }
                // self.applicationNumber = newIntakeNo
            })
        },
        makeid(lenTotal,cb){        
        // console.log(lenTotal,"lenTotal")
        var zeroes = 6-lenTotal.toString().length        
        for (let i = 0; i < zeroes; i++) {
            // console.log("0")
            lenTotal='0'+lenTotal
        }
        cb(lenTotal)
        },
        createNewApplicationFunction()
        {
            var self = this
            self.$refs.personalInfoForm.validate().then(success=>{
                // console.log(this.filterList,"filterListfilterList")    
                if(this.filterList.length > 0){
                    self.toggleChecked = [];
                    this.filterList.forEach(element=>{
                        if(element.allSelected == true){
                            self.toggleChecked.push(element)
                        }
                    })
                }else{
                   self.toggleChecked = [];
                   this.selectedCllgError = 'course not available' 
                }
                //HERE
                setTimeout(()=>{
                    if(success && self.toggleChecked.length!=0)
                    {
                        var selectedlist = [];
                        if(self.toggleChecked && self.toggleChecked.length!=0)
                        {
                            self.toggleChecked.forEach(data=>{
                                selectedlist.push({
                                    allSelected: data.allSelected,
                                    college_name: data.college_name,
                                    courseCode: data.courseCode,
                                    courseIndex: data.courseIndex,
                                    dispCourseName: data.dispCourseName,
                                    dispFieldName: data.dispFieldName,
                                    dispIntake: data.dispIntake,
                                    dispIntakeDeadline: data.dispIntakeDeadline,
                                    dispmodeOfApplication: data.dispmodeOfApplication,
                                    examsScore: data.examsScore,
                                    fieldId: data.fieldId,
                                    id: data.id,
                                    isDeleted: data.isDeleted,
                                    status: data.status||'',
                                    university_name: data.university_name,
                                })
                            })
                        }

                        self.isSpiner = true;
                        let obj = {
                            'applicationNumber':self.applicationNumber,
                            'selectedStudent':self.studentName,
                            'intake':self.intake,
                            'selectedCollege':selectedlist,
                            'application_Status':'pending',
                            'createdAt' : firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                            'updatedAt' : firebaseTimeStemp.firestore.FieldValue.serverTimestamp(),
                            'isDeleted': false,
                            'isActive':true
                        }
                        inserQuery.addDataToRootCollection(dbCollections.APPLICATIONS,obj,res=>{  
                            self.isSpiner = false;
                            let updateId={
                                'id':res.id
                            }
                            this.$toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: 'Document Added Successfully',
                                        icon: 'CoffeeIcon',
                                        variant: 'success',                               
                                    },
                            }) 
                            updateQuery.updateRootCollectionDataByDocId(dbCollections.APPLICATIONS,res.id,updateId,res=>{
                                // console.log('Success',success)
                                // console.log('Colleges',obj)
                                self.$root.$emit('stopSpinner')
                                self.$router.push({name:'application-mgmt'})
                            })
                        })                     
                    }
                    else
                    {
                        self.$root.$emit('stopSpinner')
                    }
                    if(self.toggleChecked.length == 0){
                        this.selectedCllgError="Please Selecte any College";
                    }
                },1000)
                //END               
            })
        },
        allCollegeSelection(){
            // console.log(e)
        },
        //FUN 4..
        getFieldAndCourses(id,cb){ 
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.FIELDANDCOURSE,id,res=>{
            cb(res)
            })
        },
        //getUni
        getUniversityData(uniId,cb){
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.UNIVERSITIES,uniId,res=>{
            cb(res)
            })
        },
        //FUN 3..
        findCourseData(data,cb){
            // console.log(data,"SEE HERE")
            if(Object.keys(data).length > 0){ //EACH COLEEGE
            let unidata = null
            if(data.universityId !== undefined && data.universityId !== "" ){
                this.getUniversityData(data.universityId,response=>{
                    unidata = response
                    if(data.no_of_course !== undefined && data.no_of_course !== null){ //COURCESSS ADDED [here data.no_of_course  contain array of course]
                        let count = 0;
                        let fieldAndCourses = []
                        const uploadFileFunction = (row) => {
                            if(count >= data.no_of_course.length){ 
                            cb(fieldAndCourses)                        
                            return;             
                            }
                            if(count < data.no_of_course.length){ 
                            if(row !== undefined){
                                this.getFieldAndCourses(row.fieldName.id,(saveRes)=>{
                                if(saveRes !== undefined){ //SAVERES COLLECTION DATA OF FIELDANDCOURSE
                                    let courseName = ''; 
                                    let courseCode = '';
                                    let fieldId = saveRes.id;
                                    let fieldName = '';
                                    let modeOfApplication = '';
                                    let intake = '';
                                    let applicationDeadline = ['']
                                    let examsScore = '';                          
                                    let findCourceNameInd = saveRes.courses.findIndex(ele=>{
                                    return ele.id == row.courseName.id;
                                    })
                                    let findcllgCourse = data.no_of_course.findIndex(values=>{
                                    return row.courseName.id == values.courseName.id
                                    })
                                    if(findcllgCourse > -1){                          
                                    modeOfApplication = data.no_of_course[findcllgCourse].modeOfApplication;
                                    intake = data.no_of_course[findcllgCourse].intake;                   
                                    fieldName = data.no_of_course[findcllgCourse].fieldName.name
                                    fieldId = data.no_of_course[findcllgCourse].fieldName.id
                                    applicationDeadline = data.no_of_course[findcllgCourse].applicationDeadline
                                    examsScore=data.no_of_course[findcllgCourse].exams
                                    }
                                    if(findCourceNameInd > -1){
                                    courseName = saveRes.courses[findCourceNameInd].name; 
                                    courseCode = saveRes.courses[findCourceNameInd].id;   
                                    }                             
                                    if(applicationDeadline.length > 0){
                                        applicationDeadline.forEach(deadlineDate=>{
                                            deadlineDate.DispDate = moment(new Date(deadlineDate.date)).format('DD MMM, YYYY') 
                                        })                                
                                    } 
                                    fieldAndCourses.push({
                                        'id':data.id,
                                        'courseIndex':findCourceNameInd,
                                        'isDeleted':data.isDeleted||false,
                                        'dispCourseName':courseName, 
                                        'courseCode':courseCode,
                                        'dispFieldName':saveRes.fieldName,
                                        'fieldId':fieldId,
                                        'examsScore':examsScore,
                                        'dispmodeOfApplication':modeOfApplication,
                                        'dispIntake':intake,
                                        'college_name':data.college_name,
                                        'address':data.address,
                                        'university_name':unidata.universityName ? unidata.universityName : data.university_name,
                                        'no_of_course':data.no_of_course,
                                        'city':data.city,
                                        'state':data.state,
                                        'dispIntakeDeadline':applicationDeadline,
                                        'country':data.country,
                                        'course':saveRes,

                                        'tutionFee':row.tutionFees,
                                        'applicationFee':row.applicationFees,
                                    }) //PUSH EACH COURSES                            
                                }                
                                // fieldAndCourses.push(saveRes)
                                    count += 1;
                                    uploadFileFunction(data.no_of_course[count]);
                                }); 
                            } 
                            }            
                        }
                        uploadFileFunction(data.no_of_course[count]); 
                    }else{
                        cb(false)
                    }
                })
            }          
            // setTimeout(()=>{
          
            // },1000)
            }else{
            cb(false)
            }               
        },
        //FUN 2..
        collegeCources(data,cb){
            //data is array of all colleges.
            if(data.length > 0){
            let count = 0;
            let eachRes = []
            const uploadFileFunction = (row) => {
                if(count >= data.length){ 
                    cb(eachRes)                     
                    return;                  
                }
                if(count < data.length){
                    if(row !== undefined){
                    this.findCourseData(row,(saveRes)=>{
                        //Make cource Array start    
                        if(saveRes !== null || saveRes.length !== 0 || saveRes !== false){             
                        eachRes.push({'cource':saveRes,'cllg':data})
                        }
                        count += 1;
                        uploadFileFunction(data[count]);
                    });
                    }
                }              
            }
            uploadFileFunction(data[count]);    
            }else{
            cb(false)
            }    
        }, 
      bindSelectedCollege(data,shortlisted,cb){

          if(this.applicationList.length > 0){
            this.applicationList.forEach((elem)=>{
                if(elem.courseUniqueId == data.courseUniqueId){
                    elem.allSelected = true;
                    // this.checkedAll();
                    if(shortlisted !== undefined && shortlisted.length > 0){
                        let searchIndex = shortlisted.findIndex(vals=>{
                            return vals.courseUniqueId == elem.courseUniqueId
                        })
                        if(searchIndex > -1){
                        elem.shortlisted = true   
                        }else{
                        elem.shortlisted = false   
                        }
                        cb(true)
                    }else{
                        cb(true)
                    }
                }
            })
          }else{
          cb(false)
          }
      },
      bindEditData(){
          var self = this;
           // EDIT APPLICATION DATA
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.APPLICATIONS,this.$route.params.id,response=>{
                if(response){                    
                    this.applicationNumber = response.applicationNumber
                    this.studentOptions.push({value:{name:response.selectedStudent.name,id:response.selectedStudent.id},text:response.selectedStudent.name})
                    this.studentName = response.selectedStudent
                    this.intake = response.intake
                    this.prevSelected =  response.selectedCollege;
                    if(response.selectedCollege.length > 0){
                        // if(response.shortListedColleges !== undefined){
                            let count = 0;
                            let fileArry = response.selectedCollege; 
                            const uploadFileFunction = (row) => {
                            if(count >= fileArry.length){

                                var selectedClgs = 0
                                this.applicationList.forEach(vals=>{
                                    {
                                        if(vals.allSelected == true)
                                        {
                                            selectedClgs++
                                        }
                                    }
                                })
                                if(selectedClgs == this.applicationList.length)
                                {
                                    this.allToggleChecked = true
                                }

                                return;
                            }else{
                                // if(response.shortListedColleges !== undefined)
                                self.bindSelectedCollege(row,response.shortListedColleges,(saveRes)=>{
                                    count += 1;
                                    uploadFileFunction(fileArry[count]);
                                }); 
                            }             
                            }
                            uploadFileFunction(fileArry[count]);
                        // }
                        //  this.filterList.forEach(ele=>{
                        //      console.log(ele)
                        //  })       
                    }
                }
            })
      },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkedAll(){   
            this.selectedCllgError="";
            if(this.allToggleChecked !== false){
              
                if(this.applicationList.length > 0){
                    this.applicationList.forEach(val=>{
                        val.allSelected = true
                    })                      
                }
            }
            else{            
            if(this.applicationList.length > 0){
                this.applicationList.forEach(val=>{
                    val.allSelected = false
                })                      
            }
            this.selectedCllgError="Please Selecte any College";
            }
        },
        checkedEachAppl(e){
            let allselected = true;
            this.selectedCllgError="";
            // IF ITS SHORTLISTED AND UNSELECT
            if(e.item.shortlisted == true){            
                this.$root.$emit('showToastMessage','Do not remove shortlisted item from suggested list.','danger')
                this.$router.push({name:'application-mgmt'})
            }
            if(this.applicationList.length > 0){
                this.applicationList.forEach(ele=>{
                    if (ele.allSelected == true) {
                        let findIndex = this.previousArray.findIndex((e) => e.courseUniqueId == ele.courseUniqueId)
                        if (findIndex == -1) {
                            this.previousArray.push(ele);
                        }
                    } else {
                        let findIndex = this.previousArray.findIndex((e) => e.courseUniqueId == ele.courseUniqueId)
                        if (findIndex!= -1) {
                            this.previousArray.splice(findIndex, 1);
                        }
                    }
                    if(ele.allSelected ==false){
                        allselected = false
                    }
                })
                if(allselected == true){
                    this.allToggleChecked = true;
                }else{
                    this.allToggleChecked = false;
                }
            }

            this.$refs.appTable.refresh()
        },         
        // filterByCollege(data){
        //     return data.filter(clg => !clg.college_name.indexOf(this.collegeFilter))
        // },
        // filterByUniversity(data){
        //     return data.filter(clg => !clg.university_name.indexOf(this.universityFilter))
        // },
        // filterByField(data){
        //     return data.filter(clg => !clg.dispFieldName.indexOf(this.fieldFilter))
        // },
        // filterByCourse(data){
        //     return data.filter(clg => !clg.dispCourseName.indexOf(this.courseFilter))
        // },
        filterByIntake(data){
            // return data.filter((clg) => {
            //     var found = false
            //     clg.dispIntake.forEach(val=>{
            //         if(!val.indexOf(this.intakeFilter))
            //         {
            //             found = !val.indexOf(this.intakeFilter)
            //         }
            //     })
            //     return found;
            // })
        },
        filterByMOA(data){
            // return data.filter(clg => clg.dispmodeOfApplication.toLowerCase()==this.modeOfApp.value.toLowerCase())
        },
        filterByExam(data){
            // return data.filter((clg) => {
            //     var found = false
            //     clg.examsScore.forEach(val=>{
            //         if(!val.title.indexOf(this.examFilter))
            //         {
            //             found = !val.title.indexOf(this.examFilter)
            //         }
            //     })
            //     return found;
            // })
        },
        filterByScore(data){
            // return data.filter((clg) => {
            //     var found = false
            //     clg.examsScore.forEach(val=>{
            //         if(val.score<=this.bandScore.value)
            //         {
            //             found = val.score<=this.bandScore.value
            //         }
            //     })
            //     return found;
            // })
        },
        filterByGermanlang(data){
            // return data.filter(clg => {
            //     return clg.course.germanLanguage==this.germanLang.value
            // })
        }
    },
    computed: {
        filterList()
        {
            var listArr = this.applicationList
            var tmp = listArr

            // if(this.collegeFilter!= null)
            // {
            //     tmp = this.filterByCollege(tmp)
            // }
            // if(this.universityFilter!= null)
            // {
            //     tmp = this.filterByUniversity(tmp)
            // }
            // if(this.fieldFilter!= null)
            // {
            //     tmp = this.filterByField(tmp)
            // }
            // if(this.courseFilter!= null)
            // {
            //     tmp = this.filterByCourse(tmp)
            // }
            // if(this.intakeFilter!= null)
            // {
            //     tmp = this.filterByIntake(tmp)
            // }
            // if(this.examFilter!= null)
            // {
            //     tmp = this.filterByExam(tmp)
            // }
            // if(this.bandScore!= null)
            // {
            //     tmp = this.filterByScore(tmp)
            // }
            // if(this.modeOfApp!= null)
            // {
            //     tmp = this.filterByMOA(tmp)
            // }
            // if(this.germanLang!= null)
            // {
            //     tmp = this.filterByGermanlang(tmp)
            // }

            // return listArr;
            if(this.collegeFilter==null && this.universityFilter==null && this.fieldFilter==null && this.courseFilter==null && this.intakeFilter==null && this.examFilter==null && this.bandScore==null && this.modeOfApp==null && this.germanLang == null)
            {
                return this.applicationList
            }
            else
            {
                return tmp;
            }
            // return this.filterByCollege(this.filterByUniversity(this.filterByField(this.filterByCourse(this.filterByIntake(this.filterByExam(this.applicationList))))))
        },
        suggestedCollegesList(){
            var tempArry = this.applicationList;
            var filteredArry = []; 
            if(this.collegeFilter != null){
                if(filteredArry.length <= 0){
                this.applicationList.forEach((ele,index)=>{
                    if(ele.college_name == this.collegeFilter){           
                        if(index <=  Number(this.applicationList.length - 1)){
                            filteredArry.push(ele)
                        }else{
                            return;
                        }
                    }
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    if(ele.college_name == this.collegeFilter){           
                        if(index <=  Number(filteredArry.length - 1)){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                    })
                }
                tempArry = filteredArry;
            }
            if(this.universityFilter != null){
                if(filteredArry.length <= 0){
                this.applicationList.forEach((ele,index)=>{
                    if(ele.university_name == this.universityFilter){           
                        if(index <=  Number(this.applicationList.length - 1)){
                            filteredArry.push(ele)
                        }else{
                            return;
                        }
                    }
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    if(ele.university_name == this.universityFilter){           
                        if(index <=  Number(filteredArry.length - 1)){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                    })
                }
                tempArry = filteredArry;
            }   
            if(this.fieldFilter != null){
                if(filteredArry.length <= 0){
                this.applicationList.forEach((ele,index)=>{
                    if(ele.dispFieldName == this.fieldFilter){           
                        if(index <=  Number(this.applicationList.length - 1)){
                            filteredArry.push(ele)
                        }else{
                            return;
                        }
                    }
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    if(ele.dispFieldName == this.fieldFilter){           
                        if(index <=  Number(filteredArry.length - 1)){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                    })
                }
                tempArry = filteredArry;
            }   
            if(this.courseFilter != null){
                if(filteredArry.length <= 0){
                this.applicationList.forEach((ele,index)=>{
                    if(ele.dispCourseName == this.courseFilter){           
                        if(index <=  Number(this.applicationList.length - 1)){
                            filteredArry.push(ele)
                        }else{
                            return;
                        }
                    }
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                    if(ele.dispCourseName == this.courseFilter){           
                        if(index <=  Number(filteredArry.length - 1)){
                            let fielIndex = filteredArry.findIndex(vaal=>{
                                    return vaal.id == ele.id
                            })
                            if(fielIndex <= -1){
                            filteredArry.push(ele)
                            }
                        }else{
                            filteredArry = [];
                            return;
                        }
                    }
                    })
                }
                tempArry = filteredArry;
            } 
            if(this.intakeFilter != null){
                if(filteredArry.length <= 0){
                this.applicationList.forEach((ele,index)=>{
                    let indexIntake = ele.dispIntake.findIndex(intele=>{
                        return intele == this.intakeFilter
                    })
                    if(indexIntake > -1){                        
                        filteredArry.push(ele)                        
                    }                 
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                        let indexIntake = ele.dispIntake.findIndex(intele=>{
                        return intele == this.intakeFilter
                        })
                        if(indexIntake > -1){         
                            let searchInd = filteredArry.findIndex(elem=>{
                                return elem.id == ele.id;
                            })   
                            if(searchInd <= -1){            
                            filteredArry.push(ele)
                            }                        
                        }   
                    })
                }
                tempArry = filteredArry;
            } 
            if(this.examFilter != null){
                if(filteredArry.length <= 0){
                this.applicationList.forEach((ele,index)=>{
                    let indexIntake = ele.examsScore.findIndex(intele=>{
                        return intele.title == this.examFilter
                    })
                    if(indexIntake > -1){                        
                        filteredArry.push(ele)                        
                    }                 
                })
                }else{
                    filteredArry.forEach((ele,index)=>{
                        let indexIntake = ele.examsScore.findIndex(intele=>{
                        return intele.title == this.examFilter
                        })
                        if(indexIntake > -1){         
                            let searchInd = filteredArry.findIndex(elem=>{
                                return elem.id == ele.id;
                            })   
                            if(searchInd <= -1){            
                            filteredArry.push(ele)
                            }                        
                        }   
                    })
                }
                tempArry = filteredArry;
            }
            return  tempArry
        },
        dataMeta(){
            const localItemsCount = this.applicationTable ? this.applicationTable.length : 0
            return {
            from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
            to: this.perPage * (this.currentPage - 1) + localItemsCount,
            of: this.filterList.length,
            }
        } 
    },

    beforeDestroy()
    {
        this.$root.$off('updateEditApplication')
        this.fetchMoreDataStop = true;
    }
}
</script>

<style scoped>
.position-relative.p-0_table.table-responsive {
    padding: 0px!important;
    margin-top:1.5rem;
}
.p0_table.create-application_wrapper{ padding: 0px!important;}
.remarktext__elisis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 200px;
}
</style>